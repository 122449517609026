.navBar {
  position: fixed;
  width: 100%;
  height: var(--navbarHeight);
  padding: var(--u2);
  box-sizing: border-box;
  box-shadow: var(--shadow-header-mob);
  background-color: var(--background);
  top: 0;
  z-index: var(--layer-4);

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: var(--maxWidth);
    margin: 0 auto;

    .left {
      display: flex;
      align-items: center;

      .logo {
        cursor: pointer;
        width: 110px;
      }
    }

    .faucet {
      cursor: pointer;
      color: var(--primary);
    }
    .faucet:hover {
      color: #34c53a;
    }

    .center {
      .linksContainer {
        display: flex;
        list-style: none;
        font-weight: 500;
        gap: var(--u3);

        .link {
          color: var(--text-color);
          cursor: pointer;
          padding: var(--u) var(--u2);
          border-radius: 25px;

          &:hover {
            background: var(--text-color);
            color: var(--background);

            a {
              color: var(--background);
            }
          }
        }

        .active {
          @extend .link;
          background: var(--text-color);
          a {
            color: var(--background);
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: var(--u3);
      cursor: pointer;

      .networkContainer {
        display: flex;
        gap: var(--smallUnit);
        align-items: center;
        font-weight: 400;

        .network {
          margin: 0;
          color: var(--text-color);
        }

        .dot {
          background: var(--success);
          border-radius: 50%;
          width: var(--u);
          height: var(--u);
        }

        .dotError {
          background: var(--error);
          border-radius: 50%;
          width: var(--u);
          height: var(--u);
        }
      }

      .buttonContainer {
        width: 150px;
        height: 50px;
        display: flex;
        align-items: center;
      }
    }
    //Only for Testnet Demo
    .discordFeedbackLink {
      display: flex;
      justify-content: center;
      color: var(--primary);
      text-decoration: underline;
      text-underline-offset: var(--smallUnit);
    }
    .discordFeedbackLink:hover {
      color: #34c53a;
    }
    .theme {
      cursor: pointer;
      margin-left: var(--u2);
    }
  }
}

@media (max-width: 769px) {
  .navBar {
    display: none;
  }
}
